html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Evita scroll horizontal */
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ocupar toda a altura da tela */
  background: linear-gradient(to right, #6BAE75, #F9B233);
  overflow: hidden;
}

.container {
  position: relative;
  width: 100%;
  max-width: 90%;
  padding: 10 20px; /* Padding para espaçamento */
}

.additional-message {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #333333;
  margin-top: 15px;
  text-align: center;
}

.plexus-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Certifica que fica atrás de tudo */
}

h1, p {
  margin: 0;
  text-align: center;
}


.App.green {
  background-color: #6BAE75;
}

.App.beige {
  background-color: #e6ddd4;
}

.App.silver {
  background-color: #A8A9AD;
}

.App.turquoise {
  background-color: #40E0D0;
}

.App.gold {
  background-color: #E8B200;
}

.App.grey {
  background-color: #333333;
}

.App.black {
  background-color: #000000;
}

.App.gradient {
  background: linear-gradient(120deg, #6BAE75, #E8B200);
}


.hero {
  text-align: center;
}

.logo {
  width: 150px; /* Aumentado o tamanho da logo */
  margin-bottom: 10px; /* Reduzido o espaço com a headline */
}

.headline {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 3rem;
  margin: 0;
}

.subtitle {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400; /* Regular */
  font-size: 1.5rem; /* Aumentado para mais destaque */
  margin-top: 10px;
}

.highlight {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600; /* Semibold */
}

.buttons {
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600; /* Semibold */
  color: #333333;
  border: none;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.primary-button {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600; /* Semibold */
  background-color: #E8B200;
  color:#333333;
  border: 1px solid rgba(51, 51, 51, 0.5);
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

.secondary-button {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600; /* Semibold */
  background-color: transparent;
  color: #333333;
  border: 1px solid rgba(51, 51, 51, 0.5);
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

.primary-button:hover {
  background-color: #E8B200;
  color:#333333;
}

.secondary-button:hover {
  background-color: #E8B200;
  color:#333333;
}

.buttons:hover .primary-button {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600; /* Semibold */
  background-color: transparent;
  color: #333333;
  border: 1px solid rgba(51, 51, 51, 0.5);
  border-radius: 5px;
  
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}


/* Configurações gerais para mobile */
@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .headline {
    font-size: 1.8rem; /* Reduz o tamanho da fonte do título */
    text-align: center;
  }

  .subtitle {
    font-size: 1rem; /* Ajuste menor para subtítulos */
    text-align: center;
    margin: 10px 0;
  }

  .additional-message {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.0rem;
    font-weight: 400;
    color: #333333;
    margin-top: 15px;
    text-align: center;
  }

  .plexus-background {
    height: 100%; /* Garante que o canvas se ajuste corretamente */
  }

  .buttons {
    display: flex;
    flex-wrap: wrap; /* Permite que os botões quebrem linha */
    justify-content: center;
    gap: 10px; /* Espaçamento entre os botões */
    margin-top: 20px;
  }

  .buttons .button,
  .buttons .primary-button,
  .buttons .secondary-button {
    width: 100%; /* Botões ocupam toda a largura em mobile */
    max-width: 300px; /* Define um limite para o tamanho dos botões */
  }

  .grid-container {
    grid-template-columns: 1fr; /* Reduz para uma coluna em mobile */
    grid-template-rows: auto;
    gap: 20px;
  }
}
