.container {
    background-color: rgba(245, 245, 245, 0.37); /* Fundo translúcido */
    border-radius: 20px; /* Bordas arredondadas */
    padding: 15px; /* Espaçamento interno */
    width: 92vw; /* 90% da largura da tela */
    height: 83vh; /* 80% da altura da tela */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4); /* Sombra suave */
    backdrop-filter: blur(10px); /* Efeito de desfoque no fundo */
    border: 1px solid rgba(255, 255, 255, 0.5); /* Borda suave */
    margin: 0 auto; /* Centralização automática */
    text-align: center; /* Centraliza o conteúdo interno */
    display: flex; /* Layout flex */
    flex-direction: column; /* Alinha o conteúdo verticalmente */
    justify-content: center; /* Centraliza verticalmente o conteúdo */
    align-items: center; /* Centraliza horizontalmente o conteúdo */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }

  .container:hover {
    transform: scale(1.02); /* Leve aumento no tamanho */
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.3); /* Sombra mais intensa ao passar o mouse */
  }

  @keyframes color { 
    0% {animation-timing-function: steps(1, end);border: 5px solid #43d5ff;}
    40% {animation-timing-function: steps(1, end);border: 5px solid #eeda53;}
    80% {animation-timing-function: steps(1, end);border: 5px solid #ff3434;}
    100% {border: 5px solid #2ed0f9;}
  }
  
  @keyframes blink { 
    0% {opacity:0.8;}
    50% {opacity:0.5;}
    100% {opacity:0.8;}
  }
  
  @keyframes glow {
    0% {animation-timing-function: steps(1, end);box-shadow:0px 0px 20px 4px #338ec2;}
    40% {animation-timing-function: steps(1, end);box-shadow:0px 0px 20px 4px #bb9026;}
    80% {animation-timing-function: steps(1, end);box-shadow:0px 0px 20px 4px #da2c2c;}
    100% {box-shadow:0px 0px 20px 4px #0ff;}
  }

 

  .grid-container {
    display: flex;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    gap: 10px;
    width: 92vw; /* 90% da largura da tela */
    height: 83vh; /* 80% da altura da tela */
    background-color: rgba(245, 245, 245, 0.37); /* Fundo translúcido */
    border-radius: 20px; /* Bordas arredondadas */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4); /* Sombra suave */
    backdrop-filter: blur(10px); /* Efeito de desfoque no fundo */
    border: 1px solid rgba(255, 255, 255, 0.5); /* Borda suave */
    margin: 0 auto; /* Centralização automática */
    text-align: center; /* Centraliza o conteúdo interno */
    flex-direction: column; /* Alinha o conteúdo verticalmente */
    justify-content: center; /* Centraliza verticalmente o conteúdo */
    align-items: center; /* Centraliza horizontalmente o conteúdo */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    animation:color 20s infinite linear, blink 1s infinite linear, glow 20s infinite linear
  }
  
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px;
  }
  
  @media (max-width: 768px) {
    .container {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 10px;      
    }
    .center-container {
      display: flex;
      padding: 5px;
    }
  }
  

  
  