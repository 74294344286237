.plexus-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /*background: linear-gradient(120deg, #6BAE75, #E8B200);*/
  border: 1px solid rgba(255, 255, 255, 0.5); /* Borda suave */
  backdrop-filter: blur(10px); /* Efeito de desfoque no fundo */
  background-color: rgba(51, 51, 51, 0.022);
  border-radius: 20px; /* Bordas arredondadas */
  /*animation:color 20s infinite linear, blink 1s infinite linear, glow 20s infinite linear*/
}

@keyframes color { 
  0% {animation-timing-function: steps(1, end);border: 5px solid #43d5ff;}
  40% {animation-timing-function: steps(1, end);border: 5px solid #eeda53;}
  80% {animation-timing-function: steps(1, end);border: 5px solid #ff3434;}
  100% {border: 5px solid #2ed0f9;}
}

@keyframes blink { 
  0% {opacity:0.8;}
  50% {opacity:0.5;}
  100% {opacity:0.8;}
}

@keyframes glow {
  0% {animation-timing-function: steps(1, end);box-shadow:0px 0px 20px 4px #338ec2;}
  40% {animation-timing-function: steps(1, end);box-shadow:0px 0px 20px 4px #bb9026;}
  80% {animation-timing-function: steps(1, end);box-shadow:0px 0px 20px 4px #da2c2c;}
  100% {box-shadow:0px 0px 20px 4px #0ff;}
}

@media (max-width: 768px) {
  .plexus-background {
    position: relative; /* Garante que o canvas fique dentro dos limites */
    height: 50vh; /* Define uma altura fixa para mobile */
  }
}