@media (max-width: 768px) {
  .container {
    width: 95%; /* Largura maior em telas pequenas */
    height: 85%; /* Altura maior em telas pequenas */
    padding: 20px; /* Menos espaçamento interno */
  }
}

body.login {
  background-image: none;
  background-color: #8BB5D0;
}

.container {
  background-color: rgba(245, 245, 245, 0.37); /* Fundo translúcido */
  border-radius: 20px; /* Bordas arredondadas */
  padding: 40px; /* Espaçamento interno */
  width: 90vw; /* 90% da largura da tela */
  height: 80vh; /* 80% da altura da tela */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4); /* Sombra suave */
  backdrop-filter: blur(10px); /* Efeito de desfoque no fundo */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Borda suave */
  margin: auto; /* Centralização automática */
  text-align: center; /* Centraliza o conteúdo interno */
  display: flex; /* Layout flex */
  flex-direction: column; /* Alinha o conteúdo verticalmente */
  justify-content: center; /* Centraliza verticalmente o conteúdo */
  align-items: center; /* Centraliza horizontalmente o conteúdo */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.container:hover {
  transform: scale(1.02); /* Leve aumento no tamanho */
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.3); /* Sombra mais intensa ao passar o mouse */
}

.container-buttons .btn{
  padding: 10px 20px;
  border-radius: 15px;
  border: #f4f4f4;
  margin: 10px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.subtext {
  font-family: 'IBM PLex sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
    max-width: 90%;
  }
}

.signature {
  position: fixed;
  bottom: 15px; /* Ajusta a distância do canto inferior */
  right: 15px; /* Ajusta a distância da lateral direita */
  font-family: 'Great Vibes', cursive; /* Define a fonte cursiva */
  font-size: 0.7rem; /* Tamanho maior para destaque */
  color: #FFD700; /* Cor dourada */
  opacity: 0.9; /* Torna um pouco discreto */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4); /* Sutil sombra para destaque */
}

.signature a {
  color: inherit; /* Usa a mesma cor do texto */
  text-decoration: none; /* Remove sublinhado */
}

.signature a:hover {
  text-decoration: underline; /* Adiciona sublinhado ao passar o mouse */
  color: #C0C0C0; /* Prateado no hover */
  opacity: 1; /* Torna a assinatura mais visível no hover */
}

.signature--silver {
  color: #C0C0C0; /* Alternativa em prata */
}

.highlight {
  color: #FFD700; /* Cor dourada que se destaca */
  font-weight: 700;
  text-decoration: underline; /* Mantém o sublinhado para ênfase */
  transition: color 0.3s ease; /* Animação para suavizar mudanças de cor */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.highlight:hover {
  color: #FFA500; /* Um laranja mais intenso ao passar o mouse */
}

