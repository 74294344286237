.heroSection {
    text-align: center;
    padding: 10px 5px;
  }
  
  .additional-message {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: #ffffff;
    margin-top: 20px;
    text-align: center;
  }
  
  .title {
    font-size: 5rem;
    font-weight: bold;
    color: #163A56; /* Nova cor do texto */
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
  }

  .headline {
    font-family: 'Poppins', sans-serif;
    font-weight: bold; /* Certifica que será bold */
    font-size: 3rem;
    margin: 0;
  }
  
  
  .subtitle {
    font-size: 2.4rem;
    font-weight: 400;
    color: #f4f4f4; /* Nova cor do subtítulo */
    font-weight: normal;
    -webkit-text-stroke: 0.4px FFD700;
    line-height: 1.5;
    margin-top: 10px;
    margin-bottom: 70px;
    font-family: 'IBM Plex sans', sans-serif;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    white-space: normal; /* Permite que o texto quebre automaticamente */
    word-wrap: break-word;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .highlight {
    color: #FFD700; /* Cor dourada que se destaca */
    font-weight: 700;
    transition: color 0.3s ease; /* Animação para suavizar mudanças de cor */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }
  
  .highlight:hover {
    color: #FFA500; /* Um laranja mais intenso ao passar o mouse */
  }
  
  .container {
    background-color: rgba(245, 245, 245, 0.37); /* Fundo translúcido */
    border-radius: 20px; /* Bordas arredondadas */
    padding: 20px; /* Espaçamento interno */
    width: 90vw; /* 90% da largura da tela */
    height: 80vh; /* 80% da altura da tela */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4); /* Sombra suave */
    backdrop-filter: blur(10px); /* Efeito de desfoque no fundo */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Borda suave */
    margin: auto; /* Centralização automática */
    text-align: center; /* Centraliza o conteúdo interno */
    display: flex; /* Layout flex */
    flex-direction: column; /* Alinha o conteúdo verticalmente */
    justify-content: center; /* Centraliza verticalmente o conteúdo */
    align-items: center; /* Centraliza horizontalmente o conteúdo */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .logo {
    max-width: 100px; /* Tamanho ajustado para mobile */
    margin: 20px auto;
  }
  
  .headline {
    font-size: 1.5rem; /* Menor para telas pequenas */
    text-align: center;
margin-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .subtitle {
    font-size: 1rem; /* Reduzido para mobile */
    text-align: center;
    margin: 5px 0;
  }
  
  @media (max-width: 768px) {
    .logo {
      max-width: 80px; /* Ajusta ainda mais para telas menores */
      margin: 10px auto;
    }
  
    .headline {
      font-size: 1.2rem;
    }
  
    .subtitle {
      font-size: 0.9rem;
      margin-bottom: 10px;
    }

    
  }
  