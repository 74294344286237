/* Estilo base */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: transparent;
    color: #FFD700;
    font-family: 'Poppins', sans-serif;
    text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
  }
  
  .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .navbar-toggler {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
  }
  
  .navbar-links {
    display: flex;
    list-style: none;
    gap: 40px;
  }
  
  .navbar-link {
    text-decoration: none;
    color: #333333;
    font-family: 'Poppins', sans-serif;
    text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
    font-size: 1rem;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .navbar-toggler {
      display: block; /* Exibe o botão hambúrguer */
    }
  
    .navbar-links {
      display: none; /* Esconde os links inicialmente */
      flex-direction: column;
      background-color: rgba(245, 245, 245, 0.37);
      backdrop-filter: blur(10px); /* Efeito de desfoque no fundo */
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      padding: 10px;
      border-radius: 20px; /* Bordas arredondadas */
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4); /* Sombra suave */
    }
  
    .navbar-links.open {
      display: flex; /* Mostra os links quando o menu é aberto */
    }
  
    .navbar-link {
      padding: 10px 0;
      color: #333333;
      font-family: 'Poppins', sans-serif;
      text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
      text-align: center;
    }
  }
  